import React, { useState } from 'react'

import Main from '../components/Main'

function IndexPage() {
  return (
    <Main activePane='personal' />
  )
}


export default IndexPage
